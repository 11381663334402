import WButton from 'Components/js/ui/Button.vue'
import WLink from 'Components/js/ui/Link.vue'
import WCheckbox from 'Components/js/ui/Checkbox.vue'
import WInput from 'Components/js/ui/Input.vue'
import WSelect from 'Components/js/ui/Select.vue'
import WModal from 'Components/js/ui/Modal.vue'
import WRecaptcha from 'Components/js/ui/Recaptcha.vue'
import WRecaptchaInvisible from 'Components/js/ui/RecaptchaInvisible.vue'
import WTextarea from 'Components/js/ui/Textarea.vue'
import WSwitch from 'Components/js/ui/Switch.vue'

import WDropdownMenu from 'Components/components/AppDropdownMenu.vue'
import WDatepicker from 'Components/components/AppCalendar.vue'
import WForm from 'Components/components/AppForm.vue'
import WTable from 'Components/components/AppTable.vue'
import AppBonusModal from 'Components/components/AppBonusModal.vue'

export {
    WButton,
    WLink,
    WCheckbox,
    WInput,
    WSelect,
    WModal,
    WRecaptcha,
    WRecaptchaInvisible,
    WTextarea,
    WSwitch,
    WDropdownMenu,
    WDatepicker,
    WForm,
    WTable,
    AppBonusModal
}
